import {Col, Row, Spin, Upload} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import ImportErrorDisplay from "./ImportErrorDisplay";
import MyOk from "../../../tk/bits/MyOk";
import MyError from "../../../tk/bits/MyError";


const FileImportDialog = (props) => {
    const {
        uploadHint,
        file,
        setFile,
        customError,
        onReset,
        extraOptions,
        fileList,
        setFileList,
        precheckResult,
        setPrecheckResult,
        onTest,
        onReadyForImportChanged,
        disabled,
        reuploadToken,
        setReuploadToken,
        preview
    } = props;

    const [reuploadTokenInt, setReuploadTokenInt] = useState(100);
    const reuploadTokenWrapped = reuploadToken !== undefined ? reuploadToken : reuploadTokenInt;
    const setReuploadTokenWrapped = setReuploadToken !== undefined ? setReuploadToken : setReuploadTokenInt;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
            if (file === null) return;
            if (reuploadTokenWrapped === 100) return;
            setReuploadTokenWrapped(100);
            setIsLoading(true);
            onTest({
                onSuccess: response => {
                    setIsLoading(false);
                    onReadyForImportChanged(response.data);
                },
                onError: response => {
                    setIsLoading(false);
                    onReadyForImportChanged({success: false});
                },
                file,
                setResult: setPrecheckResult
            });
        },
        [file, onReadyForImportChanged, onTest, reuploadTokenWrapped, setPrecheckResult, setReuploadTokenWrapped]
    );

    return (
        <Spin spinning={isLoading}>
            <Row>
                <Col span={extraOptions ? 12 : 24} style={{paddingBottom: "30px"}}>
                    <Upload.Dragger
                        name="files"
                        multiple={false}
                        maxCount={1}
                        fileList={fileList}
                        disabled={disabled}
                        onChange={(info) => {
                            if (info.fileList.length === 0) {
                                setPrecheckResult(null);
                                onReadyForImportChanged({success: false});
                                if (onReset !== undefined) {
                                    onReset();
                                }
                                setFile(null);
                            }
                            setFileList(info.fileList);
                        }}
                        beforeUpload={(file) => {
                            setPrecheckResult(null);
                            setFile(file)
                            return true;
                        }}
                        customRequest={(options => {
                            onTest({
                                onSuccess: response => {
                                    onReadyForImportChanged(response.data);
                                    options.onSuccess(response);
                                },
                                onError: response => {
                                    onReadyForImportChanged({success: false});
                                    options.onError(response);
                                },
                                file,
                                setResult: setPrecheckResult
                            })
                        })}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">
                            {uploadHint}
                        </p>
                    </Upload.Dragger>
                </Col>
                {extraOptions &&
                    <Col span={12}>{extraOptions}</Col>
                }
            </Row>
            <Row>
                <Col>
                    {precheckResult && (
                        <>
                            <br/>
                            {!precheckResult.success &&
                                <>
                                    <h3>Precheck with errors <MyError/></h3>
                                    <ImportErrorDisplay customError={customError} result={precheckResult}/>
                                </>
                            }
                            {precheckResult.success && preview &&
                                <>
                                    <h3>Precheck OK <MyOk/></h3>
                                    <ImportErrorDisplay customError={customError} result={precheckResult}/>
                                    {precheckResult.hint &&
                                        precheckResult.hint
                                    }
                                    <p>Ready to import file.</p>
                                </>
                            }
                        </>
                    )}
                </Col>
            </Row>
        </Spin>
    )
}

FileImportDialog.propTypes = {
    apiPath: PropTypes.string,
    uploadHint: PropTypes.string,
    onReadyForImportChanged: PropTypes.func,
    file: PropTypes.object,
    setFile: PropTypes.func,
    customError: PropTypes.func
}

export default FileImportDialog;