import EntityHeader from "../entities/EntityHeader";
import React, {useContext} from "react";
import entityDefs from "../entities/entityDefs";
import useEnumType from "../../../hooks/useEnumType";
import {FormExtContext} from "../../../tk/forms/FormExt";
import ReferenceLookupButton from "../getreferencemetadata/ReferenceLookupButton";
import DisabledTooltip from "../../../tk/bits/DisabledTooltip";
import FormBackground from "../../../tk/forms/FormBackground";
import ResponsiveCardCol from "../../../tk/cards/ResponsiveCardCol";
import EntityFormItem from "../../../tk/forms/EntityFormItem";
import TextAreaField from "../../../tk/input/TextAreaField";
import InputLimitedField from "../../../tk/input/InputLimitedField";
import UriInputField from "../../../tk/input/UriInputField";
import NumberInputField from "../../../tk/input/NumberInputField";
import SelectEntityField from "../../../tk/input/SelectEntityField";
import SelectField from "../../../tk/input/SelectField";
import SelectMultiEntityField from "../../../tk/input/SelectMultiEntityField";
import UsagesDisplay from "./UsagesDisplay";
import ResolveUri from "../../../tk/bits/ResolveUri";
import MySettingsContext from "../../../contexts/MySettingsContext";
import RelatedAttachmentTableField from "../../../tk/inputComplex/RelatedAttachmentTableField";
import ButtonsInHeader from "../entities/ButtonsInHeader";

const uriConditions = watchUri => [
    {
        isTrue: !watchUri || watchUri.length === 0,
        message: "URI is required"
    }
];

const ReferenceDetail = (props) => {
    const {entity, entityDef} = props;

    const {onClone} = useContext(MySettingsContext);
    const referenceStatuses = useEnumType('referenceStatuses');
    const referenceTypes = useEnumType('referenceTypes');
    const {formState, setValue, watch} = useContext(FormExtContext);
    const watchValues = watch();

    const handleImport = data => {
        for (const key of Object.keys(data)) {
            setValue(key, data[key], {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true
            });
        }
    }

    const makeCitation = (withDoi) => {
        const staffs = getStaffs(watchValues);
        const year = getYear(watchValues);
        let sep = '';
        if (staffs !== '' || year !== '') {
            sep = ': ';
        }
        const c = staffs + year + sep + getForCitationNoComma(watchValues.title) + getForCitation(watchValues.source)
            + getForCitation(watchValues.journal?.name) + getForCitation(watchValues.volume) + getForCitation(watchValues.pages);

        if (withDoi) return c + getForCitation(watchValues.uri);
        return c;
    }

    const getStaffs = values => {
        if (!values.staffs) return '';
        return values.staffs
            .reduce((acc, current) => acc === '' ? current.name : acc + '; ' + current.name, '');
    }

    const getYear = values => {
        if (!values.year || values.year < 1) return '';
        return ' (' + values.year + ')';
    }

    const getForCitation = value => {
        if (!value || value.trim() === '') return '';
        return ', ' + value;
    }

    const getForCitationNoComma = value => {
        if (!value || value.trim() === '') return '';
        return value;
    };


    return (
        <>
            <EntityHeader
                heading={makeCitation(true)}
                entity={entity}
                entityDef={entityDef}
                onClone={() => onClone(entity, entityDef)}
                cloneDisabledMessage={formState.isDirty ? entityDef.label + " has unsaved changes" : undefined}
                isDirty={formState.isDirty}
            />
            <ButtonsInHeader style={{marginTop: '8px'}}>
                <ReferenceLookupButton
                    query={makeCitation()}
                    method="citation"
                    onImport={handleImport}
                >
                    Citation Lookup
                </ReferenceLookupButton>
                <DisabledTooltip conditions={uriConditions(watchValues.uri)}>
                    <ReferenceLookupButton
                        query={watchValues.uri}
                        method="uri"
                        onImport={handleImport}
                    >
                        Metadata Update
                    </ReferenceLookupButton>
                </DisabledTooltip>
                <DisabledTooltip conditions={uriConditions(watchValues.uri)}>
                    <ResolveUri uri={watchValues.uri}>
                        Open DOI/URI
                    </ResolveUri>
                </DisabledTooltip>
            </ButtonsInHeader>
            <FormBackground>
                <ResponsiveCardCol no={0}>
                    <EntityFormItem label='URI' paramName='uri'>
                        <UriInputField autoFocus={true}/>
                    </EntityFormItem>
                    <EntityFormItem label='Author(s)' paramName='staffs' entityDef={entityDefs.staffs}>
                        <SelectMultiEntityField sortable={true}/>
                    </EntityFormItem>
                    <EntityFormItem label='Title' paramName='title'>
                        <TextAreaField/>
                    </EntityFormItem>
                    <EntityFormItem label='Source' paramName='source'>
                        <TextAreaField/>
                    </EntityFormItem>
                    <EntityFormItem label='Journal' paramName='journal' entityDef={entityDefs.journal}>
                        <SelectEntityField size="tiny"/>
                    </EntityFormItem>
                    <EntityFormItem label='Abstract' paramName='abstractText'>
                        <TextAreaField/>
                    </EntityFormItem>
                </ResponsiveCardCol>
                <ResponsiveCardCol no={1}>
                    <EntityFormItem label='Year' paramName='year'>
                        <NumberInputField min={0} max={5000}/>
                    </EntityFormItem>
                    <EntityFormItem label='Volume' paramName='volume'>
                        <InputLimitedField maxLength={20}/>
                    </EntityFormItem>
                    <EntityFormItem label='Pages' paramName='pages'>
                        <InputLimitedField maxLength={20}/>
                    </EntityFormItem>
                    <EntityFormItem label='Serial number' paramName='serialNumber'>
                        <InputLimitedField maxLength={64}/>
                    </EntityFormItem>
                    <EntityFormItem label='Status' paramName='referenceStatus'
                                    entityDef={entityDefs.referenceStatus}>
                        <SelectField values={referenceStatuses}/>
                    </EntityFormItem>
                    <EntityFormItem label="Type" paramName='referenceType'
                                    entityDef={entityDefs.referenceType}>
                        <SelectField values={referenceTypes}/>
                    </EntityFormItem>
                    <EntityFormItem label='Keywords' paramName='keywords' entityDef={entityDefs.keyword}>
                        <SelectMultiEntityField/>
                    </EntityFormItem>
                    <EntityFormItem label='Comment' paramName='comment'>
                        <TextAreaField/>
                    </EntityFormItem>
                </ResponsiveCardCol>
                <ResponsiveCardCol no={2} wide>
                    <EntityFormItem label="Attachments" paramName='attachments' border={true}>
                        <RelatedAttachmentTableField referenceId={entity?.idReference}/>
                    </EntityFormItem>
                    <UsagesDisplay entityId={entity?.me?.id} entityDef={entityDef}/>
                </ResponsiveCardCol>
            </FormBackground>
        </>
    )
}

export default ReferenceDetail;
