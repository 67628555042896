import {Button, Modal} from "antd";
import React, {useCallback, useContext, useEffect, useState} from "react";
import FormExt, {FormExtContext} from "../../../tk/forms/FormExt";
import TextAreaField from "../../../tk/input/TextAreaField";
import ReferenceLookupDialog, {getEnumRef, insetStyle} from "./ReferenceLookupDialog";
import ErrorDisplay from "../../../tk/error/ErrorDisplay";
import MyError from "../../../tk/bits/MyError";
import DetailBarButton from "../../../tk/forms/DetailBarButton";
import useEnumType from "../../../hooks/useEnumType";
import PropTypes from "prop-types";
import useWS2Axios from "../../../hooks/useWS2Axios";
import {referenceLookup} from "../../../lib/networkRequests";


const Explainer = (props) => {
    const {children} = props;
    return (
        <span style={{
            color: "#777"
        }}>
            {children}
        </span>
    )
}

const SearchForm = (props) => {
    const {disabled} = props;
    const {setFocus} = useContext(FormExtContext);
    useEffect(() => {
        const id = setTimeout(
            () => setFocus("query", {shouldSelect: true}),
            100
        );
        return () => clearTimeout(id);
    })
    return (
        <div>
            <TextAreaField paramName='query' disabled={disabled}/>
            {!disabled &&
            <Explainer>Copy a complete citation, the citation's title or DOI here</Explainer>
            }
        </div>
    )
}

const ReferenceLookupButton = (props) => {
    const {query, method, onImport, disabled, type, children} = props;
    const [open, setOpen] = useState(false);
    const [result, setResult] = useState(undefined);
    const [lookupLoading, setLookupLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const referenceStatuses = useEnumType('referenceStatuses');
    const referenceTypes = useEnumType('referenceTypes');
    const {ws2Axios} = useWS2Axios();

    let defaultSearch = {};
    if (query) {
        defaultSearch = {query}
    }

    // lookupMethod can be: uri, citation
    const lookup = useCallback((axios, method, query) => {
            setLookupLoading(true);
            setResult(undefined);
            setError(undefined);
            referenceLookup(axios, method, query)
                .then(response => {
                    setResult(response);
                    setError(undefined);
                    setLookupLoading(false);
                })
                .catch(response => {
                    setError(response.response.data.error);
                    setResult(undefined);
                    setLookupLoading(false);
                })
        },
        []
    );

    const handleImport = data => {
        const d2 = {};
        for (const p of Object.keys(data)) {
            if (data[p] === null) data[p] = '';
            if (p === 'authors') continue;
            if (p === 'matches') continue;
            if (p === 'journal') {
                d2[p] = {id: data[p].id, name: data[p].name}
                continue;
            }
            if (p === 'staffs') {
                d2[p] = data[p].map(staffs => ({id: staffs.id, name: staffs.name}));
                continue;
            }
            if (p === 'abstract') {
                d2.abstractText = data[p];
                continue;
            }
            if (p === 'id_reference_status') {
                d2.referenceStatus = getEnumRef(data[p], referenceStatuses)
            }
            if (p === 'id_reference_type') {
                d2.referenceType = getEnumRef(data[p], referenceTypes)
            }
            d2[p] = data[p];
        }

        onImport(d2);
        setOpen(false);
    }

    return (
        <>
            <DetailBarButton
                type={type}
                disabled={disabled}
                onClick={() => setOpen(true)}
            >
                {children || "Metadata Lookup"}
            </DetailBarButton>
            <Modal
                title={children || "Metadata Lookup"}
                open={open}
                footer={null}
                width="80%"
                maskClosable={true}
                onCancel={() => setOpen(false)}
                bodyStyle={{paddingLeft: "0", paddingRight: "0", paddingBottom: "0"}}
                keyboard={false}
                destroyOnClose={true}
            >
                <FormExt
                    defaultEntity={defaultSearch}
                    updateEntity={data => lookup(ws2Axios, "citation", data.query)}
                    globalErrors={[]}
                    setGlobalErrors={setError}
                >
                    <div style={{...insetStyle, marginBottom: "24px"}}>
                        <SearchForm paramName='query' disabled={query && (method === 'uri')}/>
                    </div>

                    {error &&
                    <div style={insetStyle}>
                        <h3><MyError/> Error</h3>
                        <ErrorDisplay message={error}/>
                        <br/>
                    </div>
                    }

                    <div className="ant-modal-footer">
                        {!result &&
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        }
                        <Button type={result ? undefined : 'primary'} htmlType='submit'
                                loading={lookupLoading}>Lookup</Button>
                    </div>
                </FormExt>

                {result &&
                <ReferenceLookupDialog
                    result={result}
                    onImport={handleImport}
                    onCancel={() => setOpen(false)}
                />
                }

            </Modal>
        </>
    )
}

ReferenceLookupButton.propTypes = {
    onImport: PropTypes.func,
    query: PropTypes.string,
    method: PropTypes.string,
    disabled: PropTypes.bool
}

export default ReferenceLookupButton;
